<template>
  <div class="contentCard">
    <div class="Header">
      <div class="pull_func">
        <RadioGroup type="button" v-model="typeGroup">
          <Radio label="pending">Pending</Radio>
          <Radio label="processed">Processed</Radio>
        </RadioGroup>
      </div>
      <div class="search_func">
        <Button type="success" style="width: 10rem" @click="modal1 = true"
          >Converge your code</Button
        >
        <Modal
          v-model="modal1"
          title="Converge your code"
          @on-ok="ok"
          @on-cancel="cancel"
        >
          <div
            style="
              display: flex;
              justify-content: stretch;
              align-items: flex-start;
            "
          >
            <span style="flex: none; width: 5rem">Message: </span>
            <Input
              v-model="message"
              type="textarea"
              :rows="4"
              placeholder="Leave a comment"
            />
          </div>
        </Modal>
      </div>
    </div>

    <div class="Body">
      <!-- <div style="height:50px;padding: 0.5rem; border-radius: 5px 5px 0 0; background-color: #f6f8fa; border: #e1e4e8 solid 0.0625rem;">
				<Col span="8" style="text-align: left;">
					<Button type="text" style="background-color: transparent;" icon="ios-alert-outline">{{openNum}} Open</Button>
					<Button type="text" style="background-color: transparent;" icon="md-checkmark">{{closeNum}} Closed</Button>
				</Col>
				<div style="float:right;padding-top:5px;text-align:right;width: 10%;">
					<Dropdown trigger="click" style="margin: 0 1rem; text-align: left;" placement="bottom-end" @on-click="selectSort">
						<a href="javascript:void(0)" style="color: #586069;">
							Sort
							<Icon type="ios-arrow-down"></Icon>
						</a>
						<DropdownMenu slot="list" style="width: 15rem;">
							<DropdownItem>
								Newest
								<Icon type="md-checkmark" v-if="sortMethod==1" size="18" style="float: right;"/>
							</DropdownItem>
							<DropdownItem>
								Oldest
								<Icon type="md-checkmark" v-if="sortMethod==2" size="18" style="float: right;"/>
							</DropdownItem>
							<DropdownItem>
								Most commented
								<Icon type="md-checkmark" v-if="sortMethod==3" size="18" style="float: right;"/>
							</DropdownItem>
							<DropdownItem>
								Least commented
								<Icon type="md-checkmark" v-if="sortMethod==4" size="18" style="float: right;"/>
							</DropdownItem>
							<DropdownItem>
								Recently updated
								<Icon type="md-checkmark" v-if="sortMethod==5" size="18" style="float: right;"/>
							</DropdownItem>
						</DropdownMenu>
					</Dropdown>
				</div>
				<div style="width:17%;text-align:right;padding-top:5px; float:right;">
					<Dropdown trigger="click" style="margin: 0 1rem; text-align: left;" placement="bottom-end">
						<a href="javascript:void(0)" style="color: #586069;">
							Organization
							<Icon type="ios-arrow-down" style="display: inline"></Icon>
						</a>
						<DropdownMenu slot="list" style="width: 15rem;">
							<div style="margin: 2px 0; text-align: left; padding-left: 16px;">
								<span style="font-weight: 300;">Filter by organization or owner</span>
							</div>
							<Divider style="margin: 0rem;"/>
							<div style="padding: 0.5rem;">
								<Input v-model="searchInput" placeholder='Filter by organization' />
							</div>
							<div>
								username
							</div>
						</DropdownMenu>
					</Dropdown>
				</div>
				<div style="float:right;padding-top:5px;text-align:right;width: 15%;">
					<Dropdown trigger="click" style="margin: 0 1rem; text-align: left;" placement="bottom-end" @on-click="selectSort">
						<a href="javascript:void(0)" style="color: #586069;">
							Visibility
							<Icon type="ios-arrow-down"></Icon>
						</a>
						<DropdownMenu slot="list" style="width: 15rem;">
							<DropdownItem>
								Public only
								<Icon type="md-checkmark" v-if="sortMethod==2" size="18" style="float: right;"/>
							</DropdownItem>
							<DropdownItem>
								Private only
								<Icon type="md-checkmark" v-if="sortMethod==3" size="18" style="float: right;"/>
							</DropdownItem>
						</DropdownMenu>
					</Dropdown>
				</div>
			</div> -->
      <div class="issueTable">
        <Table
          :columns="column1"
          :data="tableData1"
          v-if="typeGroup == 'pending'"
        >
          <template slot-scope="{ row, index }" slot="action">
            <Button
              type="success"
              size="small"
              style="margin-right: 5px"
              @click="review(row.id, true)"
              >Approve</Button
            >
            <Button type="error" size="small" @click="review(index, false)"
              >Reject</Button
            >
          </template>
        </Table>
        <Table
          :columns="column2"
          :data="tableData2"
          v-if="typeGroup == 'processed'"
        >
        </Table>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      // openNum: 0,
      // closeNum: 0,
      // sortMethod: 1,
      // searchInput: '',
      typeGroup: "pending",
      modal1: false,
      message: "",
      column1: [
        {
          title: "User",
          key: "user",
        },
        {
          title: "Message",
          key: "message",
        },
        {
          title: "Time",
          key: "time",
        },
        {
          title: "Action",
          slot: "action",
          width: 200,
          align: "center",
        },
      ],
      tableData1: [
        // {
        // 	user: '123',
        // 	message: 'zvwv qtqprpq',
        // 	time: '2021-6-14 20:23'
        // },
        // {
        // 	user: '222',
        // 	message: 'zvwv qtqprpq',
        // 	time: '2021-6-14 20:23'
        // },
        // {
        // 	user: '333',
        // 	message: 'zvwv qtqprpq',
        // 	time: '2021-6-14 20:23'
        // },
        // {
        // 	user: '444',
        // 	message: 'zvwv qtqprpq',
        // 	time: '2021-6-14 20:23'
        // },
        // {
        // 	user: '555',
        // 	message: 'zvwv qtqprpq',
        // 	time: '2021-6-14 20:23'
        // },
        // {
        // 	user: '666',
        // 	message: 'zvwv qtqprpq',
        // 	time: '2021-6-14 20:23'
        // }
      ],
      column2: [
        {
          title: "User",
          key: "user",
        },
        {
          title: "Message",
          key: "message",
        },
        {
          title: "Time",
          key: "time",
        },
      ],
      tableData2: [
        // {
        // 	user: '123',
        // 	message: 'zvwv qtqprpq',
        // 	time: '2021-6-14 20:23'
        // },
        // {
        // 	user: '222',
        // 	message: 'zvwv qtqprpq',
        // 	time: '2021-6-14 20:23'
        // },
        // {
        // 	user: '333',
        // 	message: 'zvwv qtqprpq',
        // 	time: '2021-6-14 20:23'
        // },
        // {
        // 	user: '444',
        // 	message: 'zvwv qtqprpq',
        // 	time: '2021-6-14 20:23'
        // },
        // {
        // 	user: '555',
        // 	message: 'zvwv qtqprpq',
        // 	time: '2021-6-14 20:23'
        // },
        // {
        // 	user: '666',
        // 	message: 'zvwv qtqprpq',
        // 	time: '2021-6-14 20:23'
        // }
      ],
    };
  },
  methods: {
    // selectSort(name){

    // }
    ok() {
      if (this.message.length == 0) {
        this.$Message.warning("Message cannot be empty!");
        return;
      }
      var Tself = this;
      console.log(this.$cookies.get("contributionId"));
      this.$axios({
        headers: {
          "Content-Type": "application/json",
        },
        method: "get",
        url:
          "/pit/commitToPatent?contributionId=" +
          this.$cookies.get("contributionId") +
          "&message=" +
          this.message,
      })
        .then(function (response) {
          var data = response.data;
          console.log(data);
          if (data == "commit success") {
            Tself.$Message.success("Success");
            Tself.$router.go(0);
          } else {
            Tself.$Message.error("System error");
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    cancel() {},
    review(id, result) {
      console.log(id);
      var Tself = this;
      this.$axios({
        headers: {
          "Content-Type": "application/json",
        },
        method: "get",
        url:
          "/pit/review?projectId=" +
          this.projectId +
          "&isPass=" +
          result +
          "&id=" +
          id,
      })
        .then(function (response) {
          var data = response.data;
          console.log(data);
          if (data == "success") {
            Tself.$Message.success("Success");
            Tself.$router.go(0);
          } else {
            Tself.$Message.error("System error");
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    init() {
      var val = this.$route.query.id;
      this.projectId = val;
      var Tself = this;
      this.$axios({
        headers: {
          "Content-Type": "application/json",
        },
        method: "get",
        url: "/pit/getCommits?Id=" + this.projectId + "&flag=false",
      })
        .then(function (response) {
          var data1 = response.data;
          Tself.tableData1 = [];
          for (var i = 0; i < data1.length; i++) {
            Tself.tableData1.push({
              id: data1[i].id,
              user: data1[i].userId,
              message: data1[i].message,
              time: data1[i].date,
            });
          }
        })
        .catch(function (error) {
          console.log(error);
        });

      this.$axios({
        headers: {
          "Content-Type": "application/json",
        },
        method: "get",
        url: "/pit/getCommits?Id=" + Tself.projectId + "&flag=true",
      })
        .then(function (response) {
          var data2 = response.data;
          Tself.tableData2 = [];
          for (var i = 0; i < data2.length; i++) {
            Tself.tableData2.push({
              user: data2[i].userId,
              message: data2[i].message,
              time: data2[i].date,
            });
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
  },
  mounted() {
    this.init();
  },
};
</script>

<style>
.Header {
  height: 50px;
  float: left;
  text-align: left;
  width: 100%;
  margin-top: 0px;
}

.pull_func {
  width: 50%;
  float: left;
}

.search_func {
  width: 50%;
  padding-right: 4%;
  float: left;
  text-align: right;
}

.Body {
  padding: 10px 0%;
  float: left;
  width: 100%;
  margin-bottom: 20px;
}
.body_input {
  margin-right: 1%;
  margin-left: 1%;
}
</style>
